.guardian-angle-section-contact{
    /* padding: 2rem; */
    padding: 10px;
    border-radius: 8px;
    box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.1);
 
  }
  .guardianangel-dropdown-notification-section{
    
    
  }
  .monitoring-image{
    height: 250px;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-Fit: cover;
    width: auto;
    object-fit: cover;

}
@media(max-width:1000px){

}
@media(max-width:900px){
    .guardian-angle-section-contact{
        /* padding: 2rem; */
        padding: 3px;
        border-radius: 8px;
        box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.1);
     
      }
}
@media(max-width:768px){
    .guardianangel-dropdown{
        padding: 30px;
         
     }
     .guardian-angle-section-contact{
        /* padding: 2rem; */
        padding: 15px;
        border-radius: 8px;
        box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.1);
     
      }
}
@media(max-width:520px){
.guardianangel-dropdown{
   padding: 30px;
    
}
.guardian-angle-section-contact{
    /* padding: 2rem; */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.1);
 
  }
}