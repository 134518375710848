.homeScreencontainer{
 width: 90%; 
}
.homeScreencontainerBody{
    background:linear-gradient(-45deg,rgb(236, 200, 206),rgb(176, 176, 183));
}
.feelingToday {
    margin-top: 50px;
    background-color: blue;
    min-height: 500px;
    height: auto;
    padding: 20px;
    
}
.feelingToday_new{
    margin-top: 60px;
    /* background-color: blue;  #182ba5*/

    background: linear-gradient(20deg, #00387D,#00387D);

    min-height: auto;
    height: auto;
    padding: 20px;
    
}
.circleImage_new{
    height: 100%;
    /* max-height: 200px; */
    width: 80%;
    /* max-width: 520px; */
    border-radius: 50%; /* Equivalent to setting borderRadius to 520px for a perfect circle */
}
.howAreyou, .fellingToday {
    color: white;
    font-size: 60px;
    font-weight: 700;
    margin-left: 10px;
}
.emojiContainer {
    padding-top: 50px;
    display: flex;
    text-align: center; 
    margin: 20px;
    overflow-x: auto;
    scrollbar-width: none; /* For Firefox */
    width: auto;
    margin: 0 auto;
    
}
/* .emojiContainer::-webkit-scrollbar {
    display: none; 

} */

.emoji_new {
    height: 100px;
    width: 100px;
    padding: 10px;
    cursor: pointer;
}

.circleImage {
    height: 80%;
    max-height: 520px;
    width: 100%;
    max-width: 520px;
    border-radius: 50%; /* Equivalent to setting borderRadius to 520px for a perfect circle */
    position: absolute;
    right: 0;
    bottom: 0px;
}
.circleImageContainer{
    display: block;
}
.signupLearn{
    padding: 100px;
    justify-content: center;
    gap: 50px;
}
.Signup{
    width: 180px;  
}
.LearnMore{
 width: 250px;
}
.Signupbutton{
  border-radius: 20px;
  background-color: rgb(60, 60, 121);
  color:white;
}
.LearnMoreButton{
    border-radius: 20px;
    background-color: white;
    color:blue;
}
.members{
     justify-content: center;
     align-items: center;
     text-align: center;
     
    }
.membersButton_new{
    width:200px;
    height:120px;
    font-size: x-large;
    background-color: white;
    color: black;
    border-radius: 20px;
    justify-content: center;
}
.discoverCounsellor{
    font-size: 50px;
    font-weight: 800;
}


.counsellorList_new{
    display: flex;
    overflow-x: auto;
    justify-content: center;
    gap: 10px;
    scrollbar-width: none; 
   
}

.counsellor{
   
    border: 5px solid #ccc; /* Adjust the border size and color as needed */
    border-radius: 10px; /* Rounded corners (optional) */
    padding: 15px; /* Space inside the border */
    margin-right: 15px; /* Space between slides */
    background-color: #ffffff;
    text-align: center;
}

.blog-card{
   
    border: 5px solid #ccc; /* Adjust the border size and color as needed */
    border-radius: 10px; /* Rounded corners (optional) */
    padding: 15px; /* Space inside the border */
    margin-right: 15px; /* Space between slides */
    background-color: #ffffff;
    text-align: center;
    height: 350px;
}
.slider-container {
    background-color: #ccc;
    /* background:linear-gradient(-45deg,rgb(236, 200, 206),rgb(176, 176, 183)); */
    padding: 10px 50px 30px 50px;
    border-radius: 10px;
}

.counsellorImg_new{
    width: 100%;
    height: 100%;
    border-radius: 3px 3px 0px 0px;
}
.join-button-new{
    width: 50%;
}
.counsellorName_new{
    padding-top: 10px;
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
    
}
.counsellorRating_new{
    color:rgb(19, 84, 19);
    margin: 5px;
}
.starfill,.star{
    padding: 5px;
    font-size: 25px;
}
.Insight{
    margin-top: 100px;
    font-size: 50px;
    font-weight: 700;
}
.graphFirst,.graphSecond, .graphThird {
    max-Height: 90%;
    max-Width: 90%;
    object-Fit: cover;
    border-Radius: 12px;
}
.graphFour{
    margin-Top: 40px;
    max-Height: 90%;
    max-Width: 90%;
    object-Fit: cover;
    border-Radius: 12px;
}
.firstGraph {
    cursor: pointer;
    transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); /* Smooth transition */
  }
  
  .firstGraph:hover {
    
    transform: scale(1.05) translate(-10px, -10px); /* Slight zoom and subtle shift to the left and top */
  }
  .SecondGraph {
    cursor: pointer;
    transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); /* Smooth transition */
  }
  
  .SecondGraph:hover {
    transform: scale(1.05) translate(10px, -10px); /* Slight zoom and subtle shift to the right and top */
  }
  .ThirdGraph {
    
    cursor: pointer;
    transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); /* Smooth transition */
  }
  
  .ThirdGraph:hover {
    
    transform: scale(1.05) translate(-10px, 10px); /* Slight zoom and subtle shift to the left and down */
  }
  
  .FourGraph {
    cursor: pointer;
    transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); /* Smooth transition */
  }
  
  .FourGraph:hover {
    transform: scale(1.05) translate(10px, 10px); /* Slight zoom and subtle shift to the right and down */
  }
/* .getInTouch{
    height: 107px;
    width: 100%;
    max-width: 1360px;
    background-color: #00387D;
  }
  .getInTouchHeader{
    font-size: 30px;
    
  }
 
.getInTouchHeaderBody{
  height: 61px;
  width: 100%;
  max-width: 620px;
  min-width: 200px;
}
.getInTouchEmail{
  outline: none;
  min-width: 100px;
}
.letsTalkBody{
  height: 48px;
  width: 168px;
  min-width: 50px;
}
.letsTalk{
color: #00387D;
font-size: x-large;
} */
.emgiContaier{
    width: 90%;
 }


@media(min-width:2000px) and (max-width:3000px){
    .homeScreencontainer{
        width: 80%; 
       }
       .homeScreencontainerBody{
           /* background:linear-gradient(-45deg,rgb(236, 200, 206),rgb(176, 176, 183)); */
        }
       .feelingToday {
           margin-top: 50px;
           background-color: blue;
           min-height: 500px;
           height: auto;
           padding: 20px;
           
       }
       .feelingToday_new{
           margin-top: 60px;
           background-color: blue;
           min-height: auto;
           height: auto;
           padding: 20px;   
       }
       .howAreyou, .fellingToday {
        color: white;
        font-size: 100px;
        font-weight: 700;
        margin-left: 100px;
    }
       .circleImage_new{
           height: 100%;
           /* max-height: 200px; */
           width: 80%;
           /* max-width: 520px; */
           border-radius: 50%; /* Equivalent to setting borderRadius to 520px for a perfect circle */
       } .emgiContaier{
          width: 90%;
       }
      
       .emoji_new {
        height: 150px;
        width: 150px;
        padding: 10px;
        cursor: pointer;
    }
    .signupLearn{
        padding: 120px;
        justify-content: center;
        gap: 100px;
    }
    .Signup{
        width: 280px;
        
    }
    .LearnMore{
     width: 380px;
    }
    .Signupbutton{
      border-radius: 50px;
      font-size: 40px;
      background-color: rgb(60, 60, 121);
      color:white;
    }
    .LearnMoreButton{
        border-radius: 50px;
        font-size: 40px;
        background-color: white;
        color:blue;
    }
    .membersButton_new{
        width:300px;
        height:200px;
        font-size:30px;
        font-weight: 700;
        background-color: white;
        color: black;
        border-radius: 40px;
        justify-content: center;
        margin-bottom: 20px;
    }
    .discoverCounsellor{
     font-size: 55px;
    }
    .moreButton{
        border-radius: 20px;
        font-size: 25px;
        background: gray;
    }
    
    .counsellor{
       
        border: 15px solid #ccc; /* Adjust the border size and color as needed */
        border-radius: 25px; /* Rounded corners (optional) */
        padding: 15px; /* Space inside the border */
        margin-right: 15px; /* Space between slides */
        background-color: #ffffff;
        text-align: center;
    }
    .slider-container {
        background-color: #ccc;
        /* background:linear-gradient(-45deg,rgb(236, 200, 206),rgb(176, 176, 183)); */
        padding: 10px 50px 30px 50px;
        border-radius: 20px;
    }
    
    .counsellorImg_new{
        width: 659px;
        height: 550px;
    }
    .join-button-new{
        width: 50%;
    }
    .counsellorName_new{
        padding-top: 10px;
        font-size: 20px;
        font-weight: 700;
        text-transform: capitalize;
        
    }  .counsellorName_new{
        padding-top: 10px;
        font-size: 35px;
        font-weight: 800;
        text-transform: capitalize; 
    }
    .join-button-new{
        width: 60%;
        height: 60px;
        font-size: 30px;
    }
    .counsellorRating_new{
        margin: 15px;
    }
    .starfill,.star{
        padding: 5px;
        font-size: 35px;
    }
    .Insight{
        font-size: 80px;
    }
    .graphFirst,.graphSecond, .graphThird {
        max-Height: 90%;
        max-Width: 90%;
        object-Fit: cover;
        border-Radius: 12px;
       
    }
    .graphFour{
        margin-Top: 100px;
        max-Height: 90%;
        max-Width: 90%;
        object-Fit: cover;
        border-Radius: 12px;
    }
    
}
@media(min-width:3000px) and (max-width:8000px){
    .homeScreencontainer{
        width: 80%; 
       }
       .homeScreencontainerBody{
           /* background:linear-gradient(-45deg,rgb(236, 200, 206),rgb(176, 176, 183)); */
        }
       .feelingToday {
           margin-top: 50px;
           background-color: blue;
           min-height: 500px;
           height: auto;
           padding: 20px;
           
       }
       .feelingToday_new{
           margin-top: 60px;
           background-color: blue;
           min-height: auto;
           height: auto;
           padding: 20px;   
       }
       .howAreyou, .fellingToday {
        color: white;
        font-size: 150px;
        font-weight: 700;
        margin-left: 100px;
    }
       .circleImage_new{
           height: 100%;
           /* max-height: 200px; */
           width: 80%;
           /* max-width: 520px; */
           border-radius: 50%; /* Equivalent to setting borderRadius to 520px for a perfect circle */
       }
       
       .emgiContaier{
        width: 80%;
     }
       .emoji_new {
        height: 300px;
         width:300px;
        padding: 40px;
        cursor: pointer;
        
    }
     .signupLearn{
        padding: 180px;
        justify-content: center;
        gap: 150px;
    }
    .Signup{
        width: 350px;
        
    }
    .LearnMore{
     width: 550px;
    }
    .Signupbutton{
      border-radius: 50px;
      font-size: 50px;
      background-color: rgb(60, 60, 121);
      color:white;
    }
    .LearnMoreButton{
        border-radius: 50px;
        font-size: 50px;
        background-color: white;
        color:blue;
    }
    .membersButton_new{
        width:450px;
        height:250px;
        font-size:50px;
        font-weight: 700;
        background-color: white;
        color: black;
        border-radius: 50px;
        justify-content: center;
        margin-bottom: 20px;
    }
    .discoverCounsellor{
        font-size: 75px;
       }
       .moreButton{
           border-radius: 25px;
           font-size: 38px;
           background: gray;
       }
       
    
    .counsellor{
       
        border: 15px solid #ccc; /* Adjust the border size and color as needed */
        border-radius: 25px; /* Rounded corners (optional) */
        padding: 15px; /* Space inside the border */
        margin-right: 15px; /* Space between slides */
        background-color: #ffffff;
        text-align: center;
    }
    .slider-container {
        background-color: #ccc;
        /* background:linear-gradient(-45deg,rgb(236, 200, 206),rgb(176, 176, 183)); */
        padding: 10px 50px 30px 50px;
        border-radius: 20px;
    }
    
    
    .counsellorImg_new{
        width: 759px;
        height:650px;
    }
    .join-button-new{
        width: 50%;
    }
    .counsellorName_new{
        padding-top: 10px;
        font-size: 20px;
        font-weight: 700;
        text-transform: capitalize;
        
    }
    .counsellorName_new{
        padding-top: 40px;
        font-size: 45px;
        font-weight: 800;
        text-transform: capitalize;
    }
    .join-button-new{
        width: 60%;
        height: 60px;
        font-size: 30px;
    }
    .counsellorRating_new{
        margin: 20px;
    }
    .starfill,.star{
        padding: 5px;
        font-size: 35px;
    }
    .Insight{
        font-size: 90px;
    }.graphFirst,.graphSecond, .graphThird {
        max-Height:90%;
        Width: 90%;
        object-Fit: cover;
        border-Radius: 12px;
        
    }
    .graphFour{
        margin-Top: 150px;
        max-Height: 90%;
        Width: 90%;
        object-Fit: cover;
        border-Radius: 12px;
    }
    .firstGraph {
        cursor: pointer;
        transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); /* Smooth transition */
      }
      
      .firstGraph:hover {
        
        transform: scale(1.05) translate(-20px, -20px); /* Slight zoom and subtle shift to the left and top */
      }
      .SecondGraph {
        cursor: pointer;
        transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); /* Smooth transition */
      }
      
      .SecondGraph:hover {
        transform: scale(1.05) translate(20px, -20px); /* Slight zoom and subtle shift to the right and top */
      }
      .ThirdGraph {
        
        cursor: pointer;
        transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); /* Smooth transition */
      }
      
      .ThirdGraph:hover {
        
        transform: scale(1.05) translate(-20px, 20px); /* Slight zoom and subtle shift to the left and down */
      }
      
      .FourGraph {
        cursor: pointer;
        transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); /* Smooth transition */
      }
      
      .FourGraph:hover {
        transform: scale(1.05) translate(20px, 20px); /* Slight zoom and subtle shift to the right and down */
      }

}

@media(max-width:2000px){
    .homeScreencontainer{
        width: 90%; 
    }
    
}

@media(max-width:912px){
    .circleImage_new{
        height: 80%;
        /* max-height: 200px; */
        width: 80%;
        /* max-width: 520px; */
        border-radius: 50%; /* Equivalent to setting borderRadius to 520px for a perfect circle */
    }
    .membersButton_new{
        width:150px;
        height:120px;
        font-size: x-large;
        background-color: white;
        color: black;
        border-radius: 20px;
        justify-content: center;
    }
    .graphFirst,.graphSecond, .graphThird {
        max-Height: 100%;
        max-Width: 100%;
        object-Fit: cover;
        border-Radius: 12px;
       
    }
    .graphFour{
        margin-Top: 40px;
        max-Height: 100%;
        max-Width: 100%;
        object-Fit: cover;
        border-Radius: 12px;
    }
}
@media(max-width:900px){
    /* .circleImage {
        height: 70%;
        max-height: 520px;
        width: 100%;
        max-width: 520px;
        border-radius: 50%; */
         /* Equivalent to setting borderRadius to 520px for a perfect circle */
        /* position: absolute;
        right: 0;
        bottom: -100px;
    } */
     
    .membersButton_new{
        width:150px;
        height:120px;
        font-size: x-large;
        background-color: white;
        color: black;
        border-radius: 20px;
        justify-content: center;
    }
    .discoverCounsellor{
        font-size: 45px;
        font-weight: 800;
    }
}
@media (max-width: 768px) {
    .howAreyou, .fellingToday {
        font-size: 40px;
        margin-left: 5px;
    }
    .circleImage_new{
        height: 100%;
        /* max-height: 200px; */
        width: 80%;
        /* max-width: 520px; */
        border-radius: 50%; /* Equivalent to setting borderRadius to 520px for a perfect circle */
    }
    .emoji_new {
        height: 70px;
        width: 70px;
        padding: 0px;
    }
    /* .circleImage {
        height: 260px;
        width: 260px;
        right: 0px;
        bottom: -50px;
    } */
    .homeScreencontainer{
         
    }
    
    .discoverCounsellor{
        font-size: 35px;
        font-weight: 800;
    }
    /* .getInTouchHeader{
        font-size: 20px;
        padding-left: 20px;
      }
      .getInTouchHeaderBody{
        height: 55px;
        width: 70%;
        max-width: 620px;
        min-width: 200px;
      }
      .letsTalkBody{
        height: 40px;
        width: 90px;
    
      }
      .letsTalk{
        color: #00387D;
        font-size: small;
        
        } */
}
@media(max-width:700px){
    .circleImage_new{
        height: 100%;
        /* max-height: 200px; */
        width: 80%;
        /* max-width: 520px; */
        border-radius: 50%; /* Equivalent to setting borderRadius to 520px for a perfect circle */
    }
    .membersButton_new{
        width:150px;
        height:120px;
        font-size: x-large;
        background-color: white;
        color: black;
        border-radius: 20px;
        justify-content: center;
    }
    
}
@media (max-width: 576px) {
    .howAreyou, .fellingToday {
        font-size: 30px;
        margin-left: 5px;
    }
    .emoji_new {
        height: 50px;
        width: 50px;
        padding: 0px;
        
    }
    /* .circleImage {
        height: 180px;
        width: 180px;
        right: 50px;
        bottom: -30px;
    } */
    .circleImageContainer{
        display: none;
    }
    .feelingToday{
        width:auto;
        min-width: 100px;
        margin-left: 20px;
        margin-right: 10px;
        min-height: 400px;
    }
    .homeScreencontainer{
        width: 100%; 
    }
        .Signup{
            width: 100%;
            
        }
        .members{
            justify-content: center;
            align-items: center;
            text-align: center;
       }
       .membersButton_new{
        width:200px;
        height:120px;
        font-size: x-large;
        background-color: white;
        color: black;
        border-radius: 20px;
        justify-content: center;
    }
       .discoverCounsellor{
        font-size: 30px;
        font-weight: 800;
    }
    .graphFirst,.graphSecond, .graphThird {
        max-Height: 100%;
        max-Width: 70%;
        object-Fit: cover;
        border-Radius: 12px;
    }
    .graphFour{
        margin-Top: 40px;
        max-Height: 100%;
        max-Width: 70%;
        object-Fit: cover;
        border-Radius: 12px;
    }
    .firstGraph,.SecondGraph,.ThirdGraph,.FourGraph {
        cursor: pointer;
        transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); /* Smooth transition */
      }
      
      .firstGraph:hover,.SecondGraph:hover,.ThirdGraph:hover,.FourGraph:hover {
        transform: scale(1.05) translate(1px, 1px); /* Slight zoom and subtle shift to the left and top */
      }

    /* .getInTouchHeader{
        font-size: 15px;
    }
    .getInTouchHeaderBody{
        height: 40%;
        width: 60%;
        max-width: 620px;
        min-width: 200px;

      }
    .getInTouchEmail{
        height: 60%;
        min-width: 100px;
      }
      .letsTalkBody{
        height:  70%;;
        width: auto;
        min-width: 50px;
      }
    .letsTalk{
        color: #00387D;
        justify-content: center;
        text-align: center;
        padding-top: 7px;
    } */
        
}

@media (max-width: 350px) {
    .howAreyou, .fellingToday {
        font-size: 20px;
        margin-left: 5px;
    }
    .emoji_new {
        height: 50px;
        width: 50px;
        padding: 0px;
    }
    .circleImage {
        height: 150px;
        width: 150px;
        right: 50px;
        bottom: 0px;
    }
    .feelingToday{
        width:280px;
        min-width: 100px;
        margin-right: 10px;
        min-height: 400px;
    }
    .discoverCounsellor{
        font-size: 25px;
        font-weight: 800;
    }
    
}

/* @media(min-width:2000px){
    .homeScreencontainer{
        width:  50%; 
        height: 200%;
       }
       .homeScreencontainerBody{
        width: 1800px;
        height: 1600px;
        justify-content: left;
    }
    .feelingToday_new{
        margin-top: 100px;
        background-color: blue;
        min-height: auto;
        height: 1000px;
        padding: 20px;
        margin-right: -200px;
    }
} */




@media only screen and (max-width: 600px) {
    .emoji-border{
        font-family: "emoji";
        font-size: 25px;
        /* border: 1px solid white; */
        border-radius: 50%;
        /* padding: 5px 0px; */
        margin: 0px 5px 0px 16px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) {
    .emoji-border{
        font-family: "emoji";
        font-size: 30px;
        /* border: 1px solid white; */
        border-radius: 50%;
        /* padding: 5px 0px; */
        margin: 0px 5px 0px 20px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
    .emoji-border{
        font-family: "emoji";
        font-size: 40px;
        /* border: 1px solid white; */
        border-radius: 50%;
        /* padding: 5px 0px; */
        margin: 0px 5px 0px 26px;
    }
}
@media (min-width: 832px) {
    .emoji-border{
        font-family: "emoji";
        font-size: 47px;
        /* border: 1px solid white; */
        border-radius: 50%;
        /* padding: 5px 0px; */
        margin: 0px 5px 0px 31px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) {
    .emoji-border{
        font-family: "emoji";
        font-size: 60px;
        /* border: 1px solid white; */
        border-radius: 50%;
        /* padding: 5px 0px; */
        margin: 0px 5px 0px 40px;
    }
}


