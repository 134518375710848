.Search-input {
    border: unset !important; /* Start with no visible border */
    transition: border-color 0.3s; /* Smooth transition for border color */
    
  }
  
  .Search-input:focus {
    border-color: #007bff; /* Color of the border on focus */
    outline: none; /* Remove default outline */
  }
  
  .Search-input:hover {
    border-color: transparent; /* No border on hover */
  }

  
  
  .circle-bell{
    width: 60px; /* Adjust the size */
    height: 60px; /* Adjust the size */
    border-radius: 50%; /* Makes the div a circle */
    background-color: #007bff; 
    display: flex;
    align-items: center;
    justify-content: center; /* Center the text inside the circle */
    font-size: 25px; /* Adjust font size */
    color: #fff; /* Text color */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for zoom and shadow */
    cursor: pointer;
  }
  .circle-bell:hover {
    transform: scale(1.1); /* Zoom effect */
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.3); /* Enhance the shadow on hover */
}

.circle-bell-new{
  width: 60px; /* Adjust the size */
  height: 60px; /* Adjust the size */
  border-radius: 50%; /* Makes the div a circle */
  background-color: #007bff; 
  display: flex;
  align-items: center;
  justify-content: center; /* Center the text inside the circle */
  font-size: 25px; /* Adjust font size */
  color: #fff; /* Text color */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for zoom and shadow */
  cursor: pointer;
}
.circle-bell-new:hover {
  transform: scale(1.1); /* Zoom effect */
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.3); /* Enhance the shadow on hover */
}
  .institute-hero{ 
    /* margin-top: 100px; */
    position:relative;
    top: 0;
    flex-direction: left;
  }
  .circular-progress-bar{
    display: flex;
    justify-Content: center;
    align-Items: center;
    height: auto;
    margin: 20px;
  }
  .team-image{
    height: 250px;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-Fit: cover;
    width: auto; 
  }
  .event-image{
    height: 320px;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-Fit: cover;
    width: auto; 
  }
  .institute-angel{
    color:white;
    font-weight: bold;
    margin: 20px;
    text-align: center;
  }
  .institute-angle-title{
    color:white;
    text-decoration: none;
  }
  .institute-portal-section-contact{
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.1);
  }
 
  .section-title {
    font-size: 1.75rem;
    font-weight: bold;
    color: #343a40;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  .graph-institute-portal{
   
    object-fit: cover;
 }
 
  @media(max-width:945px){
    /* .event-image{
      width: 100%;
      height: 100%;
      object-Fit: cover;
      
    } */
  }
  @media(max-width:576px){
    .GuardianPortal-section-contact{
      margin:19px ;
      border-radius: 8px;
      box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.1);
    }
  }
  @media(max-width:500px){
    .circular-progress-bar{
      display: flex;
      justify-Content: center;
      align-Items: center;
      height: auto;
      margin: 0px;

      margin-top: 20px;
    }
    /* .event-image{
      width: 100%;
      height: 100%;
      object-Fit: cover,
    } */
  }

  .institute-responsive-container{
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    flex-wrap: wrap;
  }

  .institute-search{
    width: fit-content;
    min-width: 55%;
  }

  .institute-notification-button{
    position: relative;
    bottom: 3px;
  }
  @media(max-width:992px){
    .institute-search{
      width: fit-content;
      min-width: 90%;
    }
  }