.track {
    background: #ddd;
    height: 10px;
    border-radius: 5px;
    top:4px;
  }
  
  .thumb {
    height: 20px;
    width: 20px;
    background: #00387d;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }
  .track:nth-child(2){
    background:#cfe2ff
  }