.getInTouch{
    height: auto;
    width: 100%;
    background-color: #00387D;
    padding: 10px;
  }
  .getInTouchHeader{
    font-size: 30px;
  }
 
.getInTouchHeaderBody{
  height: 61px;
  width: 100%;
  max-width: 620px;
  min-width: 200px;
}
.getInTouchEmail{
  outline: none;
  min-width: 100px;
  font-size: 10px;
}
.letsTalkBody{
  height: 48px;
  width: 168px;
  min-width: 50px;
}
.letsTalk{
color: #00387D;
font-size: x-large;
}

@media(max-width:576){
      .getInTouchHeader{
        font-size: 15px;
    }
    .getInTouchHeaderBody{
        height: 40%;
        width: 60%;
        max-width: 620px;
        min-width: 200px;

      }
    .getInTouchEmail{
        height: 60%;
        min-width: 100px;
        font-size: 15px;
      }
      .letsTalkBody{
        height:  70%;;
        width: auto;
        min-width: 50px;
      }
    .letsTalk{
        color: #00387D;
        justify-content: center;
        text-align: center;
        padding-top: 7px;
    } 
}
@media(max-width:768px){
    .getInTouchHeader{
        font-size: 15px;
        padding-left: 0px;
      }
      .getInTouchHeaderBody{
        height: 55px;
        width: 70%;
        max-width: 620px;
        min-width: 200px;
      }
      .getInTouchEmail{
        
        font-size: 15px;
      }
      .letsTalkBody{
        height: 40px;
        width: 90px;
    
      }
      .letsTalk{
        color: #00387D;
        font-size: small;
        
    }
}
@media(max-width:1024px){
    .getInTouchHeader{
        font-size: 25px;
      }
      .getInTouchHeaderBody{
        height: 55px;
        width: 70%;
        max-width: 620px;
        min-width: 200px;
      }
      .getInTouchEmail{
        font-size: 20px;
      }
      .letsTalkBody{
        height: 40px;
        width: 90px;  
      }
      .letsTalk{
        color: #00387D;
        font-size: small; 
    }
}
@media(min-width:2000px) and (max-width:3000px){
    .getInTouch{
        height: 150px;
        width: 100%;
        background-color: #00387D;
        padding: 10px;
      }
    .getInTouchHeader{
        font-size: 55px;
      }
      .getInTouchHeaderBody{
        height: 80px;
        width: 90%;
        max-width: 720px;
        min-width: 200px;
      }
      .getInTouchEmail{
        height: 70%;
        min-width: 100px;
        font-size: 30px;
      }
      .letsTalkBody{
        height: 60px;
        width: 150px;
    
      }
      .letsTalk{
        color: #00387D;
        font-size: x-large;
        
    }
}

@media(min-width:3000px) and (max-width:8000px){
    .getInTouch{
        height: 200px;
        width: 100%;
        background-color: #00387D;
        padding: 10px;
      }
    .getInTouchHeader{
        font-size: 70px;
      }
      .getInTouchHeaderBody{
        height: 150px;
        width: 100%;
        max-width: 1520px;
        
      }
      .getInTouchEmail{
        height: 70%;
        min-width: 100px;
        font-size: 60px;
        
      }
      .letsTalkBody{
        height: 100px;
        width: 350px;
    
      }
      .letsTalk{
        color: #00387D;
        font-size: 60px;
        
    }
}
@media(max-width:2000px){
    .getInTouchHeader{
        font-size: 25px;
      }
      .getInTouchHeaderBody{
        height: 55px;
        width: 70%;
        max-width: 620px;
        min-width: 200px;
      }
      .letsTalkBody{
        height: 40px;
        width: 90px;
    
      }
      .letsTalk{
        color: #00387D;
        font-size: small;
        
    }
}