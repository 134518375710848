.emoji{
    font-size: 60px;
    border:1px solid red;
    border-radius: 50%;
    font-family: "Segoe UI Emoji";
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    padding-bottom: 4px;
}