

.nationalcontainer {
    margin-top: 20px;
    background-color: #f8f9fa; /* Light background */
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow */
     
}

.nationaluserHeader{
    height: 50px;
    font-size: 20px;
    font-weight: 700;
}
  
  .nationaluser {
    font-size: 20px;
    background-color: #ffffff; /* White background for each user row */
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 10px;
    transition: transform 0.2s, box-shadow 0.2s; /* Smooth hover effect */
  }
  .userName{
    
    font-weight: bold;
    text-transform: capitalize;
  }
  
  .alluserData {
    cursor: pointer;
    height: 900px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
  }
  
  /* Custom Scrollbar */
  
  
  .nationaluser:hover {
    transform: scale(1.02); /* Slight zoom on hover */
    box-shadow: 10px 16px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
  }
  /* .nationadataplace{
  }
  .nationadatauserName{
    
  } */

  .container {
    width: auto;
  }
  .UserImages {
    width: 15%;
    height: 15%; /* Adjust based on your layout */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .UserImages img {
    width: 50%;
    height: 50%;
    object-fit: cover; /* Ensures the image covers the entire container without distortion */
    object-position: center; /* Centers the image within the container */
    border-radius: 0%; /* Ensures the image itself is circular */
  }
 

  .userName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    cursor: pointer;
  }
  
  .userName.show-full-text {
    overflow: visible;
    white-space: normal;
    text-overflow: unset;
  }
  .ribbon_new_start_first{
    font-size: 10px;;
    color:	red;
  }
  .ribbon_new_start_Second{
    font-size: 10px;;
    color:blue;
  }
  .ribbon_new_start_third{
    font-size: 10px;;
    color:yellow;
  }
  .ribbon_new {
    font-size: 10px;
    font-weight: bold;
    color: yellow;
    
  }
  .ribbon_place{
    margin-right:10px ;
  }
  .ribbon_new {
    --c: #7ED0D6;
    padding: .0em 0.8em; /* you may need to adjust this based on your content */
    aspect-ratio: 1;
    display: grid;
    place-content: center;
    text-align: center;
    position: relative;
    z-index: 0;
    width: fit-content;
    box-sizing: border-box;
  }
  .ribbon_new:before {
    content:"";
    position: absolute;
    z-index: -1;
    inset: 80% 0 auto;
    aspect-ratio: 1;
    background: color-mix(in srgb, var(--c), #000 35%);
    clip-path: polygon(calc(100%/3) 0,calc(200%/3) 0,100% 90%,80% 85%,calc(200%/3) 100%,calc(100%/3) 0,calc(200%/3) 0,calc(100%/3) 100%,20% 85%,0 90%);
  }
  .ribbon_new:after {
    content: "";
    position: absolute;
    z-index: -1;

    inset: 0;
    /* background: radial-gradient(35% 35%,#0000 96%,#0003 97% 99%,#0000) var(--c); */
    background:linear-gradient(
      35% 35%, 
      rgba(0, 0, 0, 0) 96%,    /* Fully transparent at 96% */
      rgba(0, 0, 0, 0.2) 97%,  /* Slightly visible black at 97% */
      rgba(0, 0, 0, 0) 99%      /* Fully transparent at 99% */
    ), 
    var(--c);
    /* background: radial-gradient(35% 35%,#0000 96%,#0003 97% 99%,#0000) var(--c); */
    clip-path: polygon(100.00% 50.00%,89.66% 55.22%,98.30% 62.94%,86.96% 65.31%,93.30% 75.00%,81.73% 74.35%,85.36% 85.36%,74.35% 81.73%,75.00% 93.30%,65.31% 86.96%,62.94% 98.30%,55.22% 89.66%,50.00% 100.00%,44.78% 89.66%,37.06% 98.30%,34.69% 86.96%,25.00% 93.30%,25.65% 81.73%,14.64% 85.36%,18.27% 74.35%,6.70% 75.00%,13.04% 65.31%,1.70% 62.94%,10.34% 55.22%,0.00% 50.00%,10.34% 44.78%,1.70% 37.06%,13.04% 34.69%,6.70% 25.00%,18.27% 25.65%,14.64% 14.64%,25.65% 18.27%,25.00% 6.70%,34.69% 13.04%,37.06% 1.70%,44.78% 10.34%,50.00% 0.00%,55.22% 10.34%,62.94% 1.70%,65.31% 13.04%,75.00% 6.70%,74.35% 18.27%,85.36% 14.64%,81.73% 25.65%,93.30% 25.00%,86.96% 34.69%,98.30% 37.06%,89.66% 44.78%); /* from https://css-generators.com/starburst-shape/ */
  }
  /* .from{
  }
  .to{

  }
  .go{
       
  } */
  .goButton{
    border-radius: 50px;

  }   
  .Questionmark{
    font-size: 30px;
  }
  @media(max-width:500px){
    .nationalcontainer {
      margin-top: 20px;
      background-color: #f8f9fa; /* Light background */
      border-radius: 15px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow */
      
  }
  }

  @media(max-width:678px) and (max-width:1024px){
    .nationalcontainer {
      margin-top: 20px;
      background-color: #f8f9fa; /* Light background */
      border-radius: 15px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow */
     
  }
    .nationaluser {
        font-size: 10px;
        font-weight: 700;
        text-transform: capitalize;
    }
    .nationaluserHeader{  
        font-size: 13px;
        font-weight: 500;
    }
   
    .userName {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;
      cursor: pointer;
    }
    
    .userName.show-full-text {
      overflow: visible;
      white-space:normal;
      text-overflow:unset;
      /* text-overflow: unset; */
    }


  .UserImages {
    width: 30%;
    height: 30%; /* Adjust based on your layout */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .ribbon_new {
    font-size: 10px;
    font-weight: bold;
    color: yellow;
    
  }
  .ribbon_new {
    --c: #7ED0D6;
    padding: .0em 0.4em; /* you may need to adjust this based on your content */
    aspect-ratio: 1;
    display: grid;
    place-content: center;
    text-align: center;
    position: relative;
    z-index: 0;
    width: fit-content;
    box-sizing: border-box;
  }
  
  .ribbon_new:before {
    content:"";
    position: absolute;
    z-index: -1;
    inset: 70% 0 auto;
    aspect-ratio: 1;
    background: color-mix(in srgb, var(--c), #000 35%);
    clip-path: polygon(calc(100%/3) 0,calc(200%/3) 0,100% 90%,80% 85%,calc(200%/3) 100%,calc(100%/3) 0,calc(200%/3) 0,calc(100%/3) 100%,20% 85%,0 90%);
  }
  
  .ribbon_new:after {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    /* background: radial-gradient(35% 35%,#0000 96%,#0003 97% 99%,#0000) var(--c); */
    background: 
    linear-gradient(
      rgba(0, 0, 0, 0) 0%,         /* Transparent start */
      rgba(0, 0, 0, 0) 96%,        /* Transparent at 96% */
      rgba(0, 0, 0, 0.2) 97%,      /* Slightly visible black at 97% */
      rgba(0, 0, 0, 0) 99%          /* Transparent again at 99% */
    ), 
    var(--c);
  
    clip-path: polygon(100.00% 50.00%,89.66% 55.22%,98.30% 62.94%,86.96% 65.31%,93.30% 75.00%,81.73% 74.35%,85.36% 85.36%,74.35% 81.73%,75.00% 93.30%,65.31% 86.96%,62.94% 98.30%,55.22% 89.66%,50.00% 100.00%,44.78% 89.66%,37.06% 98.30%,34.69% 86.96%,25.00% 93.30%,25.65% 81.73%,14.64% 85.36%,18.27% 74.35%,6.70% 75.00%,13.04% 65.31%,1.70% 62.94%,10.34% 55.22%,0.00% 50.00%,10.34% 44.78%,1.70% 37.06%,13.04% 34.69%,6.70% 25.00%,18.27% 25.65%,14.64% 14.64%,25.65% 18.27%,25.00% 6.70%,34.69% 13.04%,37.06% 1.70%,44.78% 10.34%,50.00% 0.00%,55.22% 10.34%,62.94% 1.70%,65.31% 13.04%,75.00% 6.70%,74.35% 18.27%,85.36% 14.64%,81.73% 25.65%,93.30% 25.00%,86.96% 34.69%,98.30% 37.06%,89.66% 44.78%); /* from https://css-generators.com/starburst-shape/ */
  }
  
    
  }
  @media(max-width:1024) and (max-width:2000px){
    .nationalcontainer {
      margin-top: 20px;
      background-color: #f8f9fa; /* Light background */
      border-radius: 15px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow */
     margin-left: 30px;
     margin-right: 30px;
  }
    .nationaluser {
        font-size: 10px;
        font-weight: 700;
        text-transform: capitalize;
    }
    .nationaluserHeader{  
        font-size: 13px;
        font-weight: 500;
    }
   
    .userName {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;
      cursor: pointer;
    }
    
    .userName.show-full-text {
      overflow: visible;
      white-space:normal;
      text-overflow:unset;
      /* text-overflow: unset; */
    }


  .UserImages {
    width: 30%;
    height: 30%; /* Adjust based on your layout */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .ribbon_new {
    font-size: 10px;
    font-weight: bold;
    color: yellow;
    
  }
  .ribbon_new {
    --c: #7ED0D6;
    padding: .0em 0.4em; /* you may need to adjust this based on your content */
    aspect-ratio: 1;
    display: grid;
    place-content: center;
    text-align: center;
    position: relative;
    z-index: 0;
    width: fit-content;
    box-sizing: border-box;
  }
  
  .ribbon_new:before {
    content:"";
    position: absolute;
    z-index: -1;
    inset: 70% 0 auto;
    aspect-ratio: 1;
    background: color-mix(in srgb, var(--c), #000 35%);
    clip-path: polygon(calc(100%/3) 0,calc(200%/3) 0,100% 90%,80% 85%,calc(200%/3) 100%,calc(100%/3) 0,calc(200%/3) 0,calc(100%/3) 100%,20% 85%,0 90%);
  }
  
  .ribbon_new:after {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    /* background: radial-gradient(35% 35%,#0000 96%,#0003 97% 99%,#0000) var(--c); */
    background: 
  linear-gradient(
    rgba(0, 0, 0, 0) 0%,         /* Fully transparent at the start */
    rgba(0, 0, 0, 0) 96%,        /* Fully transparent until 96% */
    rgba(0, 0, 0, 0.2) 97%,      /* Slightly visible black at 97% */
    rgba(0, 0, 0, 0) 99%          /* Fully transparent again at 99% */
  ), 
  var(--c);

    clip-path: polygon(100.00% 50.00%,89.66% 55.22%,98.30% 62.94%,86.96% 65.31%,93.30% 75.00%,81.73% 74.35%,85.36% 85.36%,74.35% 81.73%,75.00% 93.30%,65.31% 86.96%,62.94% 98.30%,55.22% 89.66%,50.00% 100.00%,44.78% 89.66%,37.06% 98.30%,34.69% 86.96%,25.00% 93.30%,25.65% 81.73%,14.64% 85.36%,18.27% 74.35%,6.70% 75.00%,13.04% 65.31%,1.70% 62.94%,10.34% 55.22%,0.00% 50.00%,10.34% 44.78%,1.70% 37.06%,13.04% 34.69%,6.70% 25.00%,18.27% 25.65%,14.64% 14.64%,25.65% 18.27%,25.00% 6.70%,34.69% 13.04%,37.06% 1.70%,44.78% 10.34%,50.00% 0.00%,55.22% 10.34%,62.94% 1.70%,65.31% 13.04%,75.00% 6.70%,74.35% 18.27%,85.36% 14.64%,81.73% 25.65%,93.30% 25.00%,86.96% 34.69%,98.30% 37.06%,89.66% 44.78%); /* from https://css-generators.com/starburst-shape/ */
  }
  }
  
  /* @media (max-width: 1280px) and (min-width: 1024px) {
    
  } */
  @media(min-width:2000px) and (max-width:3000px){
   .nationalStanding{
    font-size: 80px;
   }
    .nationalcontainer {
      margin-left: 30px;
      margin-right: 30px;
      border-radius: 15px;
  }
  .nationaluser {
    font-size: 30px;
    font-weight: 700;
    text-transform: capitalize;
}
.nationaluserHeader{ 
    height: 80px; 
    font-size: 45px;
    font-weight: 500;
}

.ribbon_place{
  margin-right:20px ;
}
.UserImages {
width: 20%;
height: 20%; /* Adjust based on your layout */
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
}
.alluserData {
  cursor: pointer;
  height: 1000px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}
.ribbon_new_start_first{
  font-size: 15px;;
  color:	red;
}
.ribbon_new_start_Second{
  font-size:15px;;
  color:blue;
}
.ribbon_new_start_third{
  font-size: 15px;;
  color:yellow;
}
.ribbon_new {
font-size: 40px;
font-weight: bold;
color: yellow;

}
.ribbon_new {
--c: #7ED0D6;
padding: .0em 0.4em; /* you may need to adjust this based on your content */
aspect-ratio: 1;
display: grid;
place-content: center;
text-align: center;
position: relative;
z-index: 0;
width: fit-content;
box-sizing: border-box;
}

.ribbon_new:before {
content:"";
position: absolute;
z-index: -1;
inset: 70% 0 auto;
aspect-ratio: 1;
background: color-mix(in srgb, var(--c), #000 35%);
clip-path: polygon(calc(100%/3) 0,calc(200%/3) 0,100% 90%,80% 85%,calc(200%/3) 100%,calc(100%/3) 0,calc(200%/3) 0,calc(100%/3) 100%,20% 85%,0 90%);
}

.ribbon_new:after {
content: "";
position: absolute;
z-index: -1;
inset: 0;
/* background: radial-gradient(35% 35%,#0000 96%,#0003 97% 99%,#0000) var(--c); */
background: 
  linear-gradient(
    rgba(0, 0, 0, 0) 0%,         /* Fully transparent at the start */
    rgba(0, 0, 0, 0) 96%,        /* Fully transparent until 96% */
    rgba(0, 0, 0, 0.2) 97%,      /* Slightly visible black at 97% */
    rgba(0, 0, 0, 0) 99%          /* Fully transparent again at 99% */
  ), 
  var(--c);

clip-path: polygon(100.00% 50.00%,89.66% 55.22%,98.30% 62.94%,86.96% 65.31%,93.30% 75.00%,81.73% 74.35%,85.36% 85.36%,74.35% 81.73%,75.00% 93.30%,65.31% 86.96%,62.94% 98.30%,55.22% 89.66%,50.00% 100.00%,44.78% 89.66%,37.06% 98.30%,34.69% 86.96%,25.00% 93.30%,25.65% 81.73%,14.64% 85.36%,18.27% 74.35%,6.70% 75.00%,13.04% 65.31%,1.70% 62.94%,10.34% 55.22%,0.00% 50.00%,10.34% 44.78%,1.70% 37.06%,13.04% 34.69%,6.70% 25.00%,18.27% 25.65%,14.64% 14.64%,25.65% 18.27%,25.00% 6.70%,34.69% 13.04%,37.06% 1.70%,44.78% 10.34%,50.00% 0.00%,55.22% 10.34%,62.94% 1.70%,65.31% 13.04%,75.00% 6.70%,74.35% 18.27%,85.36% 14.64%,81.73% 25.65%,93.30% 25.00%,86.96% 34.69%,98.30% 37.06%,89.66% 44.78%); /* from https://css-generators.com/starburst-shape/ */
}
.from{
  font-size: 35px;
 }
 .to{
  font-size: 35px;
 }
 .forminputype{
  height: 30px;
 }



  }
 /* @media(min-width:1200px) and (max-width:1500px){
  
 } */
  
 
  
 @media(min-width:3000px) and (max-width:8000px){
  
  .nationalcontainer {
    border-radius: 15px;
    margin-left: 30px;
    margin-right: 30px;
}
.nationalStanding{
  font-size: 90px;
 }
 
.alluserData {
  cursor: pointer;
  height: 2000px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}

.ribbon_place{
  margin-right:30px ;
  margin-top: -15px;
}
.nationaluser {
  font-size: 40px;
  font-weight: 700;
  text-transform: capitalize;
}
.nationaluserHeader{ 
  margin-bottom: 20px;
  height: 100px; 
  font-size: 55px;
  font-weight: 500;
}

.UserImages {
width: 20%;
height: 20%; /* Adjust based on your layout */
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
}
.ribbon_new_start_first{
  font-size: 20px;;
  color:	red;
}
.ribbon_new_start_Second{
  font-size:20px;;
  color:blue;
}
.ribbon_new_start_third{
  font-size: 20px;;
  color:yellow;
}
.ribbon_new {
font-size: 60px;
font-weight: bold;
color: yellow;

}
.ribbon_new {
--c: #7ED0D6;
padding: .0em 0.4em; /* you may need to adjust this based on your content */
aspect-ratio: 1;
display: grid;
place-content: center;
text-align: center;
position: relative;
z-index: 0;
width: fit-content;
box-sizing: border-box;
}

.ribbon_new:before {
content:"";
position: absolute;
z-index: -1;
inset: 70% 0 auto;
aspect-ratio: 1;
background: color-mix(in srgb, var(--c), #000 35%);
clip-path: polygon(calc(100%/3) 0,calc(200%/3) 0,100% 90%,80% 85%,calc(200%/3) 100%,calc(100%/3) 0,calc(200%/3) 0,calc(100%/3) 100%,20% 85%,0 90%);
}

.ribbon_new:after {
content: "";
position: absolute;
z-index: -1;
inset: 0;
/* background: radial-gradient(35% 35%,#0000 96%,#0003 97% 99%,#0000) var(--c); */
background: 
  linear-gradient(
    rgba(0, 0, 0, 0) 0%,         /* Fully transparent at the start */
    rgba(0, 0, 0, 0) 96%,        /* Fully transparent until 96% */
    rgba(0, 0, 0, 0.2) 97%,      /* Slightly visible black at 97% */
    rgba(0, 0, 0, 0) 99%          /* Fully transparent again at 99% */
  ), 
  var(--c);

clip-path: polygon(100.00% 50.00%,89.66% 55.22%,98.30% 62.94%,86.96% 65.31%,93.30% 75.00%,81.73% 74.35%,85.36% 85.36%,74.35% 81.73%,75.00% 93.30%,65.31% 86.96%,62.94% 98.30%,55.22% 89.66%,50.00% 100.00%,44.78% 89.66%,37.06% 98.30%,34.69% 86.96%,25.00% 93.30%,25.65% 81.73%,14.64% 85.36%,18.27% 74.35%,6.70% 75.00%,13.04% 65.31%,1.70% 62.94%,10.34% 55.22%,0.00% 50.00%,10.34% 44.78%,1.70% 37.06%,13.04% 34.69%,6.70% 25.00%,18.27% 25.65%,14.64% 14.64%,25.65% 18.27%,25.00% 6.70%,34.69% 13.04%,37.06% 1.70%,44.78% 10.34%,50.00% 0.00%,55.22% 10.34%,62.94% 1.70%,65.31% 13.04%,75.00% 6.70%,74.35% 18.27%,85.36% 14.64%,81.73% 25.65%,93.30% 25.00%,86.96% 34.69%,98.30% 37.06%,89.66% 44.78%); /* from https://css-generators.com/starburst-shape/ */
}
.from{
  font-size: 35px;
 }
 .to{
  font-size: 35px;
 }
 .forminputype{

  height: 50px;
  /* You can also add padding for more control */
  padding: 100px;
  margin: 30px;
}
 }
 /* @media(min-width:1500px){
  
 } */
