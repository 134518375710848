select,input[type="checkbox"]
,input[type="number"]
,input[type="password"]
,input[type="radio"]
,input[type="text"]
,input[type="date"]
,input[type="email"]
,input[type="datetime-local"]
,input[type="file"]{
    border: 2px solid #5f7ad9 ;
}

textarea{
    border: 2px solid #5f7ad9 !important;
}

 .App {
     display: flex;
     justify-content: center;
     align-items: center;
     min-height: 100vh;
     max-height: 100%;
     width: 100%;
     background-image: url('../images/loginBackground.png');
     background-size: cover;
     background-position: center;
     background-repeat: no-repeat;

 }

 .loginForm {
     width: 100%;
     max-width: 400px;
     padding: 100px;
     background-color: #f0f0f0;
     border-radius: 10px;
     display: flex;
     flex-direction: column;
     align-items: center;
 }

 .registrationForm {
     width: 100%;
     margin: 20px 20px;
     max-width: 800px;
     padding: 100px;
     background-color: #f0f0f0;
     border-radius: 10px;
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
     align-items: center;
 }

 .glass {
     background: rgba(168, 168, 168, 0.30);
     border-radius: 16px;
     box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
     backdrop-filter: blur(2px);
     -webkit-backdrop-filter: blur(2px);
     border: 1px solid rgba(255, 255, 255, 0.47);
 }

 .form-group {
     width: 100%;
     margin-bottom: 15px;
 }

 .form-group.full-width {
     width: 100%;
     text-align: center;
 }

 .form-group.full-width h2 {
     white-space: nowrap;
     display: flex;
     justify-content: center;
     color: #001285;
     text-align: center;
     color: #001285;
     font-size: 40px;

 }

 .form-group h2 {
     white-space: nowrap;
     display: flex;
     justify-content: center;
     text-align: center;
     color: #001285;
     font-size: 45px;
     /* Adjust font size */
     margin-top: -50px;
     font-family: 'Arial', sans-serif;
     /* Adjust font family */
 }

 label {
     white-space: nowrap;
     text-overflow: ellipsis;
     display: block;
     margin-bottom: 5px;
     text-align: center;
     padding: 5px;
     border-radius: 3px;
     font-size: 16px;
     /* Adjust font size */
     font-weight: bold;
     /* Adjust font weight if needed */
     font-family: 'Arial', sans-serif;
     /* Adjust font family */
 }

 .terms {
     
     background-color: #0d6efd;
     color: white;
     padding: 10px;
     border-radius: 5px;
 }

 .terms span {
     white-space: pre-wrap;
     background-color: #0d6efd;
     color: white;
     padding: 5px;
     border-radius: 5px;
 }

 .terms-label {
     background-color: #0d6efd;
     color: white;
     padding: 10px;
     border-radius: 5px;
 }

 input[type="text"],
 input[type="password"],
 input[type="date"],
 select {
     width: 100%;
     padding: 8px;
     box-sizing: border-box;
     background-color: #ffffff;
     /* border: 2px solid #5f7ad9; */
     border-radius: 5px;
     text-align: left;
     height: 38px;
     /* Adjust height to match other inputs */
     font-size: 14px;
     /* Adjust font size to match other inputs */
     color: #333;
     /* Text color */
     font-family: 'Arial', sans-serif;
     /* Adjust font family */
 }

 input[type="text"].tnc {
     height: 150px;

 }

 input[type="checkbox"] {
     margin-right: 5px;
     transform: scale(1.5);
     background-color: #ffffff;
 }

 button {
     width: 100%;
     padding: 10px;
     background-color: #00387d;
     color: white;
     border: none;
     cursor: pointer;
     border-radius: 5px;
     margin-top: 15px;
     font-size: 16px;
     /* Adjust font size */
     font-family: 'Arial', sans-serif;
     /* Adjust font family */
     font-weight: bold;
     /* Adjust font weight if needed */
 }

 button:hover {
     background-color: #00387d;
 }

 .error {
     color: red;
     font-size: 12px;
     text-align: center;
     font-family: 'Arial', sans-serif;
     /* Adjust font family */
 }

 /* Modal styles */
 .modal-overlay {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.5);
     /* Semi-transparent background */
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .long-text {
     max-height: 150px;
     /* Limit height */
     overflow-y: auto;
     /* Enable vertical scrolling */
     padding: 10px;
     /* Optional: Add padding for better readability */
 }

 .modal {
     background-color: white;
     padding: 20px;
     border-radius: 10px;
     max-width: 80%;
     max-height: 80%;
     overflow: auto;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
 }

 .modal-content {
     text-align: center;
 }

 .close {
     position: absolute;
     top: 10px;
     right: 10px;
     cursor: pointer;
     font-size: 24px;
     color: #aaa;
 }

/* Loader and Backdrop Styles */
.loader-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loader {
  position: relative;
  width: 64px;
  height: 64px;
}

.loader::before,
.loader::after {
  content: "";
  position: absolute;
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: spin 1.2s linear infinite;
}

.loader::after {
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: #c0deff;
  border-bottom-color: #c0deff;
  animation-direction: reverse;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.required-mark::after{
    color: red;
    content: "*";
}

.footerCls{
    position: relative;
    width: 100%;
    bottom: 0;
    padding:10px
}
.footerCls>div {
    border-radius: 10px;

}
.app-header-center{
    padding: 15px 10px 10px 10px;
    display: flex;
    justify-content: space-around;
}

.modal {
    width: auto;
    width: -webkit-fill-available;
    position: fixed;
    display: block;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #747474;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 1.2);
    z-index: 1000;
    height: fit-content;
}
.modalOverlay {
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index:999 ;
}
.modalCloseButton {
    cursor: pointer;
    background-color:red;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    float: right;
    width: auto;
}
.card{
    border:solid #ffffff00 2px !important;

  }
  .card:hover{
    border:solid #8ab4f8 2px !important;
  }



.form-control{
    min-height: 44px !important;
}
.btn-primary{
    background-color: #00387d;
}
.btn-primary:hover{
    background-color: #00387d;
}
.btn-outline-primary{
    background-color: #ffffff00;
    border-color: #00387d;
    color: #00387d;
}
.btn-outline-primary:hover{
    background-color: #00387d;
}
.btn-outline-primary:active{
    background-color: #00387d !important;
}
.btn-outline-primary:focus-visible{
    background-color: #00387d;
}
.layout-div{
    background: #e5e5e5;
}

.dynamic-space-around{
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    flex-wrap: wrap;
}
.dynamic-flex-start{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
.dynamic-space-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}


select.round {
    background-image:
      linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      radial-gradient(#ddd 70%, transparent 72%);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - .5em) .5em;
    background-size:
      5px 5px,
      5px 5px,
      1.5em 1.5em;
    background-repeat: no-repeat;
  }
  
  select.round:focus {
    background-image:
      linear-gradient(45deg, white 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, white 50%),
      radial-gradient(gray 70%, transparent 72%);
    background-position:
      calc(100% - 15px) 1em,
      calc(100% - 20px) 1em,
      calc(100% - .5em) .5em;
    background-size:
      5px 5px,
      5px 5px,
      1.5em 1.5em;
    background-repeat: no-repeat;
    border-color: green;
    outline: 0;
  }
  

  .swt-cancel-btn{
    background-color: #6e7881;
    width: auto;

  }
  .swt-confirm-btn{
    background-color: #00387d;
    width: auto;
  }