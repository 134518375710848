
.counsellor-filter-button-container{
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  flex-wrap: wrap;
}
.counsellor-filter-button{
  width: fit-content;
  position: fixed;
  right: 30px;
  z-index: 1;
}

.offcanvas-bottom{
  min-height: 70vh;
  overflow: auto;
}
.accordion-button{
  border:1px solid darkgray;
}
.accordion-button:focus{
  box-shadow: unset;
}
.accordion-button:hover{
  background-color:#cfe2ff;

}
.card-div{
  padding:10px 10px 20px 10px;
}
.radio-button{
  display: flex;
  justify-content: space-between;
  background-color: #ffffff00;
  width: 100%;
  height: fit-content;
  color: black;
  border: 1px solid darkgray;
}
.radio-button:hover{
  background-color:#cfe2ff;
}
.counsellor-filter-checkbox-container{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}
.check-box-div{
  width: fit-content;
  margin: 10px;
}
.fit-content{
  width: fit-content;
}
.counsellor-title-div{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.counsellor-button-div{
  align-items: center;
  justify-content: space-between;
}
.counsellor-header-div{
  margin-top: 20px;
}
.div-div{
  min-height: 90vh;
}

.counsellor-card-img-top {
  padding: 5px;
  border-radius: 16px 16px 0px 0px;
  width: 100%;
  height: 100%;
  object-fit: cover; 
  object-position: center;
}