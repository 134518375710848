/* GuardianPortal.css */

.GuardianPortal_section {
    /* background-color: #f8f9fa; */
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 5px 5px 10px  rgba(0, 0, 0, 0.1);
  }
  .GuardianPortal_section_contact{
    /* padding: 2rem; */
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.1);
 
  }
  .guardian-portal-section-contact{
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.1);
  }
   .section-title {
    /* font-size: 1.75rem;
    font-weight: bold;
    color: #343a40;
    margin-bottom: 1.5rem;
    text-align: center; */
    
  } 
  
  .icon-large {
    font-size: 2rem;
    color: #007bff;
  }
  
  .GuardianPortal_section p {
    color: #6c757d;
  }
  
  .GuardianPortal_section h3 {
    font-size: 1.5rem;
    color: #495057;
  }
  
  .btn-lg {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .mb-5 {
    margin-bottom: 3rem;
  }
  
  .py-5 {
    padding: 3rem 0;
  }
  .circle {
    width: 140px; /* Adjust the size */
    height: 140px; /* Adjust the size */
    border-radius: 50%; /* Makes the div a circle */
    background-color: blue; /* Background color of the circle */
    display: flex;
    align-items: center;
    justify-content: center; /* Center the text inside the circle */
    font-size: 20px; /* Adjust font size */
    color: #fff; /* Text color */
    border: 5px solid blue; /* Optional: border around the circle */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for zoom and shadow */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
    cursor: pointer;
    text-shadow: 2px 2px 5px  black;
}

.circle:hover {
    transform: scale(1.1); /* Zoom effect */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Enhance the shadow on hover */
}
.guardian_portal{
  color:blue;
  text-shadow: 2px 2px 5px  black;
  text-transform: uppercase;
}
.guardian-angel{
  color:white;
  font-weight: bold;
  margin: 20px;
  text-align: center;
}
.guardian-angle-title{
  color:white;
  text-decoration: none;
}
.graph-GuardianPortal{
   
   object-fit: cover;
}

.health-image{
  width: 100%; /* Set desired width */
  height: 150px; /* Set desired height */
  object-fit: cover; /* Ensures the image covers the area, cropping as needed */
  border-radius: 10px 10px 0 0;
}


.connect-expert-container {
  max-width: 100%;
  margin: 0 auto;
}

.connect-expert-img {
  max-width: 100%;
  height: auto;
}

.connect-expert-label {
  font-weight: bold;
}

.connect-expert-input {
  width: 100%;
  box-sizing: border-box;
}

.connect-expert-textarea {
  box-sizing: border-box;
}
.connect-expert-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.connect-expert-button:hover {
  background-color: #0056b3;
}
.expert-image{
    height: 250px;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-Fit: cover;
    width: auto;
    object-fit: cover;

}
@media(max-width: 900px){
 .health-image{
    width: 100%; /* Set desired width */
    height: 120px; /* Set desired height */
    object-fit: cover; /* Ensures the image covers the area, cropping as needed */
 }
 
}

@media(max-width: 678px){
 
  .health-image{
     width: 100%; /* Set desired width */
     height: 150px; /* Set desired height */
     object-fit: cover; /* Ensures the image covers the area, cropping as needed */
  }
  
 }
/* Responsive styles */
@media (max-width: 576px) {
  .health-image{
    width: 100%; /* Set desired width */
    height: 300px; /* Set desired height */
    object-fit: cover; /* Ensures the image covers the area, cropping as needed */
    border-radius: 10px 10px 0 0;
  }
  .connect-expert-label {
    font-size: 12px;
  }
   .guardian-portal-section-contact{
    margin:19px ;
    border-radius: 8px;
    box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.1);
  }

  .connect-expert-input,
  .connect-expert-textarea {
    font-size: 14px;
  }

  .connect-expert-button {
    font-size: 14px;
    padding: 8px;
  }
  .guardian_portal{
    margin-left: 30px;
    text-shadow: 2px 2px 5px  black;
    text-transform: uppercase;
  }
  .circle {
    width: 100px; /* Adjust the size */
    height: 100px; /* Adjust the size */
    border-radius: 50%; /* Makes the div a circle */
    background-color: blue; /* Background color of the circle */
    display: flex;
    align-items: center;
    justify-content: center; /* Center the text inside the circle */
    font-size: 15px; /* Adjust font size */
    color: #fff; /* Text color */
    border: 5px solid blue; /* Optional: border around the circle */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for zoom and shadow */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
    cursor: pointer;
    text-shadow: 2px 2px 5px  black;
}

}