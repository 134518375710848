/* 
.hello{
    color:red;
}
.DownloadText{
    font-size: 50px;
}


@media (max-width: 575.98px) {
    .fz-xs-3 {
      font-size: 1rem; 
    }
  }
  
  @media (min-width: 576px) and (max-width: 767.98px) {
    .fz-sm-3 {
      font-size: 1.25rem; 
    }
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .fz-md-4 {
      font-size: 1.5rem; 
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .fz-lg-4 {
      font-size: 1.75rem; 
    }
  }
  
  @media (min-width: 1200px) {
    .fz-xl-4 {
      font-size: 2rem; 
    }
  }
   */

   .banner{
    padding: 10px;
    justify-items: center;
    justify-content: center;
   }
   .left-banner{
    padding-top: 20px;
    font-size: 1rem;
    color: white;
    font-weight: 600;
   }
   .right-banner{
    margin-bottom: 10px;
    padding-right: 20px;
   }
   
   @media(max-width:500px){
    .left-banner{
      font-size: 1rem;
      font-weight: 700;
      
     }
   }
   @media (max-width: 678px) and (min-width: 500px) {
    .left-banner{
      font-size: 1.2rem;
      font-weight: 700;
     }
   }
   @media (max-width: 1024px) and (min-width: 678px) {
    .left-banner{
      font-size: 1.3rem;
      font-weight: 700;
     }
   
    }
    @media (max-width: 1280px) and (min-width: 1024px) {
      .left-banner{
        font-size: 1.4rem;
        font-weight: 700;
       }
     
    }
   
   @media(min-width:1200px) and (max-width:1500px){
    .banner{
      padding: 20px;
    }
    .left-banner{
      font-size: 2rem;
      font-weight: 700;
     }
     .buttontext{
      font-size: 2rem;
     }
   }
   @media(min-width:1500px){
    .banner{
      padding: 20px;
    }
    .left-banner{
      font-size: 3rem;
      font-weight: 700;
     }
     .buttontext{
      font-size: 3rem;
     }
   }
