.disorder-bg-color{
    background: #e5e5e5;
}
.disorder-card-bg-color{
    background-color: #D9D9D9 !important;
    height: 320px;
    min-height: 320px;
}
.disorder-container{
    background: #e5e5e5;
   /* background: linear-gradient(45deg,rgb(171, 171, 235),rgb(204, 236, 235),red); */
    
   /* border-radius: 0px 0px 10px 10px; */
    position: relative;
    min-height: 100vh;
    /* z-index: -1; */
    /* top: -10px; */
}
.modelcontaier{
    margin-left: 10%;
   margin-right: 10%;
   display: block;
    position: fixed;
    
    background: red;
           /* width: auto;*/
}

.modaldialog{

}
.modal-content{
    
}
.Allbutton{
    position: relative;
    bottom: 0;
    display: flex;
    left: auto;
    right: auto;
    
    
}
.card{
    border: 3px solid #0d6efd !important;
    background: #fff !important;
}

.card-img-container {
    height: 250px; /* Set a fixed height */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Prevents image overflow */
}

.card-img-top {
    padding: 20px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Ensure the entire image is visible */
}
.modal-div{
    border: 2px solid orange;
    border-radius: 5px;
    margin: 10px 0px;
}


@media only screen and (max-width: 600px) {
    .card-default-padding{
        padding: 8%;
    }
    .modal{
        width: unset !important;
        min-width: 300px !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) {
    .card-default-padding{
        padding: 3%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
    .card-default-padding{
        padding: 2%;
    }
}
@media (min-width: 832px) {
    .card-default-padding{
        padding: 2%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) {
    .card-default-padding{
        padding: 1%;
    }
}

.mental-disorder-button-div{
    align-items: center;
    justify-content: space-between;
}

.mental-disorder-title-div{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

li::marker{
    color:orange;
}
