.header-container {
  margin-left: 2%;
  margin-right:2%;
  /* position: relative; */
  position: sticky;
  top: 0;
  right:0;
  z-index: 999;
  }
  
  .menu-wrapper {
    /* position:static; */
     /* position: relative; */
  }
  .allHeader{
    cursor: pointer;
  }
  .btnlight{
    color:white;
    cursor: pointer;
    border:none;
  }
  .btnlight:hover{
    color:blue;
  }
  .profile-circle,.bookmark_new,.search_new{
    font-size: 30px; 
   }
  .home,.events ,.mentalDisorders ,.leaderboard ,.merchandises
  ,.counsellorsAndTherapists ,.institutePortal ,.guardianPortal
  {
    color:white;
    font-size: medium;
    position: relative;
    text-decoration: none; /* Ensure no underline initially */
}

.home::after ,.events::after ,.mentalDisorders::after ,.leaderboard::after ,.merchandises::after
,.counsellorsAndTherapists::after ,.institutePortal::after ,.guardianPortal::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px; /* Adjust thickness as needed */
    background-color: white; /* Color of the underline */
    bottom: -6px; /* Adjust distance from the text */
    left: 0;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.home:hover::after ,.events:hover::after, .mentalDisorders:hover::after, .leaderboard:hover::after ,.merchandises:hover::after
,.counsellorsAndTherapists:hover::after ,.institutePortal:hover::after,.guardianPortal:hover::after
{
    transform: scaleX(1);
}
  
  .menu-open {
    display: flex !important;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: rgb(61, 93, 232);
    /* background-color: var(--bs-secondary); */
    padding: 3px;
    z-index: 1;
  }
 
  .profile-container {
    /* position: fixed;
    top: 0;
    left: -300px;
    width: 550px;
    height:100% ;
    overflow-y: scroll;
    scrollbar-width: none;
    background-color: rgb(156, 150, 150);
    transition: left 0.3s ease;
    padding: 20px;
    color: white;
    z-index: 1000; */

    position: fixed;
    top: 0;
    right: -550px; /* Initially hidden on the right */
    width: 550px;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    background-color: rgb(156, 150, 150);
    transition: right 0.3s ease;
    padding: 20px;
    color: white;
    z-index: 1000;  }
  .user-profile{
  /* background: rgb(96, 96, 197); */
  }
  .profile-open {
    right: 0; /* Slide in the profile container */
  }
  .cursor-pointer {
    cursor: pointer;
  }
  
  .profile-close-btn {
    color: white;
    background: red !important;
    border-radius: 10px;
    width: 50px;
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
    border: none;
    position: absolute;
    top: 10px; /* Positioning at the top */
    right: 10px; /* Positioning to the right */
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .profile-content {
    margin-top: 40px;
    text-align: center;
  }
  
  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
  }
  
  .input-group input {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 4px;
  }
  
  .update-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
  }
  
  .update-btn:hover {
    background-color: #0056b3;
  }
  .input-group input, .input-group textarea {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    max-width: 500px; /* Ensuring all inputs are of similar width */
  }
  .inputypeProfile{
      background-color: #f8f9fa; /* Light gray background */
      color: #343a40; /* Dark text color */
      border: 2px solid #007bff; /* Primary color border */
      border-radius: 5px; /* Rounded corners */
      text-align: left; /* Center the text */
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1); /* Subtle shadow */
      font-size: 15px;
      padding: 5px;
      height: 35px;
  }
  
  @media (min-width: 768px) {
    .menu-open {
      position: static;
      flex-direction: row;
      background-color: transparent;
      padding: 0;
    }
    .header-container {
      margin-left: 0%;
      margin-right:0%;
      }
  }

  @media(max-width:768px){
    .profile-container {
      position: fixed;
      top: 0;
      width: 100%;
      /* height:520px; */
      height:100%;
      overflow-y: scroll;
      scrollbar-width: none;
      /* background-color: #343a40; */
      background-color: gray;
      transition: left 0.3s ease;
      padding: 20px;
      color: white;
      z-index: 1000;
    }    
  }
  
  @media(max-width:500px){
    .header-container {
      margin-left: 0%;
      margin-right:0%;
      position: sticky;
      top: 0;
      right:0;
      width: 100%;
      z-index: 999;
      }
      .allHeader{
        overflow-y: scroll;
      }
      .menu-open {
        display: flex !important;
        flex-direction: column;
        text-align: center;
        gap: 10px;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: rgb(61, 93, 232);
        /* background-color: var(--bs-secondary); */
        padding: 10px;
        z-index: 1;

      } 
      .profile-container {
        position: fixed;
        top: 0;
        right: -300px;
        /* width: 250px; */
        /* height:520px; */
        width: 100%;
        height:100%;
        overflow-y: scroll;
        scrollbar-width: none;
        /* background-color: #343a40; */
        background-color: gray;
        transition: left 0.3s ease;
        padding: 20px;
        color: white;
        z-index: 1000;
      }
     
      .profile-open {
        right: 0; /* Slide in the profile container */
      }
      
      .profile-close-btn {
        color: white;
        background: none;
        border: none;
        position: absolute;
        top: 10px; /* Positioning at the top */
        right: 10px; /* Positioning to the right */
        font-size: 1.5rem;
        cursor: pointer;
      }
      
      .profile-content {
        margin-top: 40px;
        text-align: center;
      }
      
      .profile-image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 20px;
      }
      
      .input-group {
        margin-bottom: 15px;
      }
      
      
      
      .input-group input {
        width: 100%;
        padding: 8px;
        border: none;
        border-radius: 4px;
      }
      
      .update-btn {
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
        width: 100%;
      }
      
      .update-btn:hover {
        background-color: #0056b3;
      }
  }

  @media(max-width:1024px){
    .header-container {
      margin-left: 0%;
      margin-right:0%;
      }
  }
 


   @media (max-width: 1280px) and (min-width: 1024px) {
    
   .header-container {
    margin-left: 1%;
    margin-right:1%;
    
  }
  
  }
 
 @media(min-width:1200px) and (max-width:1500px){
  .header-container {
    margin-left: 2%;
    margin-right:2%;
    
  }

  .home,.events,.mentalDisorders,.leaderboard,.merchandises,.counsellorsAndTherapists,.institutePortal,.guardianPortal{
    font-size: medium;
    padding:0px;
  }
  
 }
 @media(min-width:1500px) and (max-width:1800px){
  .header-container {
    margin-left: 2%;
    margin-right:2%;
  }
  .home,.events,.mentalDisorders,.leaderboard,.merchandises,.counsellorsAndTherapists,.institutePortal,.guardianPortal{
    font-size:25px;
    padding: 0px;
    margin: 30px;
  
  }
  
 } 
 @media(min-width:1800px ){
  .header-container {
    margin-left: 2%;
    margin-right:2%;
  }
  .home,.events,.mentalDisorders,.leaderboard,.merchandises,.counsellorsAndTherapists,.institutePortal,.guardianPortal{
    font-size:35px;
    padding: 5px;
    margin: 50px;
    
  }
  .profile-circle,.bookmark_new,.search_new{
   font-size: 50px; 
   cursor: pointer;
  }
  .menu-wrapper{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  
 } 

 .profile-name-edit{
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  flex-wrap: wrap;
}

.select-border{
  border: 2px solid #5f7ad9 !important;
}

.edit-form {
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  border-radius: 5px;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

/* Slide down animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Slide up animation (when form is closed) */
.animate-slide-down {
  animation: fadeIn 1s forwards;
}
.profile-edit-btn{
  width: fit-content;
  height: fit-content;
  position: relative;
  background-color: #00387d;
  border-radius: 50%;
  right: 22px;
  z-index: 1;
}
.upload-profile-btn{
  width: auto;
  height: auto;
  margin: 5px;
}