/* src/components/test.css */
.pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination .page-item {
    margin: 0 5px;
  }
  
  .pagination .page-link {
    padding: 8px 12px;
    border: 1px solid royalblue;
    color: royalblue;
    cursor: pointer;
    text-decoration: none;
    border-radius: 6px;
  }
  
  .pagination .active .page-link {
    background-color: royalblue;
    color: white;
    border-color: royalblue;
  }
  
  .pagination .page-link:hover {
    background-color: royalblue;
    color: white;
  }
  
  .pagination .previous, .pagination .next {
    font-weight: bold;
  }
  