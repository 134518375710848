
.help-desk-form { 
  border:solid #ffffff00 2px !important;
    border-radius: 5px;
     height: 500px;
     padding: 10px;
  }
  
  .questionform{
    word-break: break-word;
  }
  .help-desk-form:hover{
    border:solid #8ab4f8 2px !important;
  }
  
  .institute-portal-section-contact{
    /* padding: 2rem; */
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.1);
  }
  .graph-institute-portal{
    object-fit: cover;
 }
 .hero-special-image{
  height: 250px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-Fit: cover;
  width: auto; 
}
