.aboutAndFaqs, .profile, .informationCenter, .termAndCondition, .privacyPolicy, .footer-policy, 
    .AllRightsResered, .rijuvalikaTechPrivLim, .mailto, .adminYoue, 
    .allinformation {
        font-size: 15px;
    }
.rijuvalikaTechnolog{
    word-break: break-word; /* Ensures long words break and wrap to the next line */
    overflow-wrap: break-word;    
    font-size: 15px;
}


@media(max-width:500px){
    .aboutAndFaqs, .profile, .informationCenter, .termAndCondition, .privacyPolicy, .footer-policy, 
    .AllRightsResered, .rijuvalikaTechPrivLim, .mailto, .adminYoue, .rijuvalikaTechnolog, 
    .allinformation {
        font-size: 15px;
    }
}
@media (max-width: 1280px) and (min-width: 1024px){
    .aboutAndFaqs, .profile, .informationCenter, .termAndCondition, .privacyPolicy, .footer-policy, 
.AllRightsResered, .rijuvalikaTechPrivLim, .mailto, .adminYoue, .rijuvalikaTechnolog, 
.allinformation {
    font-size: 18px;
}
}
 
@media(min-width:1280px) and (max-width:1500px){
    .aboutAndFaqs, .profile, .informationCenter, .termAndCondition, .privacyPolicy, .footer-policy, 
    .AllRightsResered, .rijuvalikaTechPrivLim, .mailto, .adminYoue, .rijuvalikaTechnolog, 
    .allinformation {
        font-size: 20px;
    }
}
 @media (min-width:1500px) and(max-width:2000px){
    .aboutAndFaqs, .profile, .informationCenter, .termAndCondition, .privacyPolicy, .footer-policy, 
    .AllRightsResered, .rijuvalikaTechPrivLim, .mailto, .adminYoue, .rijuvalikaTechnolog, 
    .allinformation {
        font-size: 22px;
    }
 }
 @media (min-width:2000px) and (max-width:2500px){
    .aboutAndFaqs, .profile, .informationCenter, .termAndCondition, .privacyPolicy, .footer-policy, 
    .AllRightsResered, .rijuvalikaTechPrivLim, .mailto, .adminYoue, .rijuvalikaTechnolog, 
    .allinformation {
        font-size: 35px;
    }
 }
 @media(min-width:2500px){
    .aboutAndFaqs, .profile, .informationCenter, .termAndCondition, .privacyPolicy, .footer-policy, 
    .AllRightsResered, .rijuvalikaTechPrivLim, .mailto, .adminYoue, .rijuvalikaTechnolog, 
    .allinformation {
        font-size: 38px;
    }
 }
 @media(min-width:3000px){
    .aboutAndFaqs, .profile, .informationCenter, .termAndCondition, .privacyPolicy, .footer-policy, 
    .AllRightsResered, .rijuvalikaTechPrivLim, .mailto, .adminYoue, .rijuvalikaTechnolog, 
    .allinformation {
        font-size: 50px;
        
    }
 }