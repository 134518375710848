.body-div{
    min-height: 90vh;
}

/* .hr-div{
    width: -webkit-fill-available;
    max-width: 95%;
    margin: auto;
}

.hr-div > div{
    border: 1px solid #ababab;
} */

.counsellor-name-div{
    width: fit-content;
    margin:0px 10px;
}
.counsellor-image-div{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Prevents image overflow */
    
}
.counsellor-img{
    height: 200px;
    width: 200px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    aspect-ratio: 1;

    transform: scale(1);
  pointer-events: none;
}



.counsellor-img img {
   zoom: 0%;
 }
.counsellor-border-iframe{
    border:1px solid #ababab;
    border-radius: 12px;
    margin: 10px;
    padding: 10px 10px 5px 10px;
    box-shadow: 2px 2px 7px 1px dimgray;
}

.counsellor-border-img{
    border:1px solid #ababab;
    border-radius: 12px;
    margin: 10px;
    padding: 10px 10px 10px 10px;
    box-shadow: 2px 2px 7px 1px dimgray;
}

.counsellor-border{
    border:1px solid black;
    border-radius: 12px;
    margin: 10px;
    padding: 10px 10px 10px 10px;
    box-shadow: 2px 2px 7px 1px dimgray;
}

.counsellor-half-div{
    min-height: calc(min(100%,400px)/2);
}
.counsellor-table-div{
    margin: 10px;
    padding: 10px;
}
div.counsellor-table-div  tr{
    border:#ffffff00;
}
.no-slot-week{
    opacity: 0.6;
}
/* .consellor-week-slot{
    display: flex;
}
@media only screen and (max-width: 1010px) {
    .consellor-week-slot{
        display: unset;
    }
} */

.individual-slots{
    position: absolute;
    height: 100px;
    width: 100px;
    padding: 5px;
    color: white;
}
.individual-slots-behind{
    background-color: rgb(40, 157, 175);
    padding: 10px;
    left: 10px;
    top: 10px;
}
.individual-slots-front{
    background-color: rgb(40, 157, 175);
    padding: 10px;
    left: 10px;
    top: 10px;
}
.individual-slots-infront{
    background-color: rgb(228, 71, 184);
    padding: 10px;
    left: 10px;
    top: 10px;
}
table.slots-table thead{
    
    border-radius: 12px !important;
}
table.slots-table th{
    padding:unset;
}
table.slots-table th>div{
    background: #00387d;
    border-radius: unset;
    color: white;
    padding:8px;
}
.offcanvas-top{
    min-height: 80vh;
    overflow: auto;
  }