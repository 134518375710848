.new-practice {
  position: relative;
  text-align: center;
}

.image-container {
  position: relative;
  display: inline-block;
}

.practice-image {
  width: 100%;
  max-width: 500px; /* Adjust width as needed */
  height: auto;
}

.overlay-text {
  position: absolute;
  top: 50%; /* Center text vertically */
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Text color */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 10px;
  font-size: 16px; /* Text size */
  border-radius: 5px;
}

.toggle-icon {
  position: absolute; /* Position icon over the image */
  top: 10px; /* Adjust position as needed */
  right: 10px; /* Adjust position as needed */
  font-size: 30px; /* Icon size */
  cursor: pointer; /* Change cursor to pointer */
  color: white; /* Icon color */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for better visibility */
  padding: 5px; /* Padding around the icon */
  border-radius: 50%; /* Rounded icon background */
}

.toggle-icon:hover {
  background-color: rgba(255, 255, 255, 0.3); /* Hover effect */
}
