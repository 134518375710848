.btn-auto-width{
    width: auto;
    position: relative;
    bottom: 10px;
}
.element-width{
    max-width: 400px;
}
.center-element{
    display: flex;
    justify-content: center;
    align-items: center;
}
.swal2-confirm{
    width: auto;
}
.swal2-deny{
    width: auto;
}